<div class="container">
  <div class="row">
    {#each weekdays as day}
      <div class="cell">{day}</div>
    {/each}
  </div>

  <div class="row">
    {#each cells as { allowed, value, calDate } (uuid())}
      <button
        type="button"
        on:click={() => dateChange(calDate)}
        class:cell={true}
        class:highlight={allowed && calDate}
        class:disabled={!allowed && calDate}
        class:selected={calDate === selected}
        class:nonDate={!calDate}
      >
        {value || ""}
      </button>
    {/each}
  </div>
</div>

<script>
  import { getDateRows, uuid } from "./date-time.js";

  const log = (a, ...rest) =>
    echo.log(echo.blue("Calendar"), echo.white.bgBlue.bold(a), ...rest);
  // props
  export let selected;
  export let dispMonth;
  export let isAllowed;
  export let showDatePicker;

  // local vars to help in render
  const weekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  let cells;
  $: log("dispMonth", dispMonth);
  // function helpers
  // const onChange = (Month) => {
  //   dispatch("datechange", date);
  // };
  const dateChange = (d) => {
    if (!d) return;
    showDatePicker = false;
    selected = d;
    // dispatch("datechange", d.detail);
  };

  const allow = (c) => {
    if (!c) return true;
    return isAllowed(c.calDate);
  };

  $: cells = getDateRows(dispMonth).map((c) => ({
    ...c,
    allowed: allow(c),
  }));
</script>

<style>
  .container {
    margin-top: 8px;
    padding: 6px;
    width: 100%;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    grid-gap: 2px;
    /* margin: 2px 6px; */
    /* flex-wrap: wrap; */
  }

  .cell {
    display: inline-block;
    width: 40px;
    height: 20px;
    text-align: center;
    padding-top: 1px;
  }

  .selected {
    background: #84e791;
  }

  .highlight {
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .disabled {
    background: #efefef;
    cursor: not-allowed;
    color: #bfbfbf;
  }
  .nonDate {
    background: #fff;
  }

  .highlight:hover {
    background: rgb(238, 176, 60);
    color: #fff;
    cursor: pointer;
    transform: scale(1.3);
  }

  .selected.highlight:hover {
    background: green;
  }
</style>
