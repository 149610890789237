<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<div id="yearNav">
  {#each years as year}
    <div class="year" class:current={curYear === year}>
      <div on:click={() => selectYear(year)} class="title">{year}</div>
      {#if curYear === year}
        {#each walks[year] as walk, i}
          <div
            on:click={() => selectWalk(walk.date, walks[year], i)}
            class="walk"
            class:current={curWalk === walk.date}
          >
            {walk.date}
            {walk.area}
          </div>
        {/each}
      {/if}
    </div>
  {/each}
</div>

<script>
  // import { createEventDispatcher } from "svelte";
  // let dispatch = createEventDispatcher();
  export let walks;

  // export let walkSelected;
  export let curWalk;
  let curYear;
  let curIndex;
  let years;
  $: years = Object.keys(walks).sort((a, b) => b.localeCompare(a));

  let sortedWalks = (walks) => {
    return walks.sort((a, b) => a.date.localeCompare(b.date));
  };
  let selectYear = (year) => {
    curYear = year;
    curWalk = null;
    console.log("selectYear", year);
  };
  let selectWalk = (walkId, walks, i) => {
    curWalk = walkId;
    console.log("selectWalk", { walkId, walks, i });
    // walkSelected(curWalk);
  };

  // const emitCurWalk = (curWalk) => {
  //   console.log("watch CurWalk", curWalk);
  //   dispatch("walkSelected", { curWalk });
  // };
  // $: emitCurWalk(curWalk);
</script>

<style lang="postcss">
  /*#yearNav {*/
  .year {
    font-size: 0.8rem;
    color: rgb(73, 49, 29);
    cursor: pointer;
    & > div {
    }
    &.current {
      border-left: thin solid #888;
      & > div.title {
        font-weight: bold;
        font-size: 1rem;
        background-color: rgb(73, 49, 29);
        color: white;
      }
    }

    & .walk {
      margin-left: 1rem;
      font-size: 0.8rem;
      color: red;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.current {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
  /*}*/
</style>
