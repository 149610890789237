<div>
  There is a summary of the
  <a href="/apiServer/downloads/leaderRoles" target="_blank"
    >Role &amp; responsibilities of a Walk Leader</a
  >can be downloaded.
  <p>
    Leaders will be picked from competent club members. Club policy is to
    encourage Members to want to lead walks, with positive support from other
    senior club members if wanted or required.
  </p>

  <p>
    The leader of a walk has the responsibility of ensure that the group has an
    enjoyable and safe day out. If you are leading a walk then there are things
    you need to do.
  </p>

  <ul class="simpleList">
    <li>
      Usually, you will have been selected as leader well in advance of a walk.
      Use that time to prepare yourself on matters concerning your walk e.g. the
      nature of the terrain, items of interest along the route, speed of group
      and to pre-plan escape routes for use in an emergency.
    </li>

    <li>
      Ensure that the party has the necessary group equipment with them, e.g.
      KSU's, Cas-straps, first-aid etc. You don't have to carry all of these
      personally but they need to be available to you should things go wrong.
    </li>

    <li>
      If you have a mobile phone, ensure that is charged up and switched on. You
      never know when one of the other groups may need to call you for
      assistance or to warn you of problems on your route ahead.
    </li>

    <li>
      Pick a "sweeper" to bring up the rear of the party before walk starts.
      This is particularly important if the party is large.
    </li>

    <li>
      Leaders, in particular, will encourage Members to look at and to use their
      maps and compasses. This not only helps breed new leaders but also
      improves the ability of the whole group to deal with unforeseen
      circumstances.
    </li>

    <li>
      As the leader, you need to be in contact with all members of your group at
      all times i.e. look to the tail and to the front for tearaways to keep
      your group as a group.
    </li>

    <li>
      If you find many "fast lasses" (or "lads"), You MAY decide it is in the
      best interests of the rest of the group to have a breakaway group. You
      must ensure that the breakaway group conforms to the minimum group size,
      has map, compass and essential safety kit with them, that they are made
      aware that their safety is to become their own responsibility and that the
      return time to the bus will be the same as that for the rest of the
      walkers.
    </li>

    <li>
      If you have any walkers in the group who are having difficulty while the
      walk is in progress, you MAY decide to split the group as above, to slow
      down or even stop the main group. It is the YOUR decision, in consultation
      with the group. Safety for ALL members of a group is paramount
      consideration. Contact with other groups to pass on such information, e.g.
      by phone checks, where possible, becomes vital. You may be able to
      transfer walkers to other groups or you may need to exercise an escape
      route.
    </li>

    <li>
      As the leader, keep walks to the published distances, time of arrival back
      at the bus and the route agreed before the walk commences. This is a
      safety requirement. If anything happens on your walk then you will be more
      easily found if you are where you are supposed to be. Also one of the
      other walks may need to contact you if they have problem which will be
      problematic if you aren't where you should be.
    </li>

    <li>
      Ensure that the group returns to the coach, where possible, no later than
      the appointed finishing time. Walks should be timed to arrive back at the
      bus AT the appointed time. It is frustrating for others if your group is
      very late OR VERY EARLY in its return.
    </li>
    <li>
      To avoid confusion in the event of an incident, the walk leader <strong
        >only</strong
      > determines appropriate action after consultation with the group as necessary.
    </li>
  </ul>
</div>
