<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<div id="regNav">
  {#each Object.entries(walks) as [reg, finishes]}
    <div class="region" class:current={curReg === reg}>
      <div on:click={() => selectRegion(reg)} class="title">{reg}</div>
      {#if curReg === reg}
        {#each Object.entries(finishes) as [finish, walks]}
          <div class="finish" class:current={curFinish === finish}>
            <div on:click={() => selectFinish(finish)} class="title">
              {finish}
            </div>
            {#if curFinish === finish}
              {#each walks as { date: wdate, area }, i}
                <div
                  on:click={() => selectWalk(wdate, walks, i)}
                  class="walk"
                  class:current={curWalk === wdate}
                >
                  {wdate}{area}
                </div>
              {/each}
            {/if}
          </div>
        {/each}
      {/if}
    </div>
  {/each}
</div>

<script>
  // import { createEventDispatcher } from "svelte";
  export let curWalk;
  // export let walkSelected;
  // let dispatch = createEventDispatcher();
  export let walks;
  let curReg;
  let curFinish;
  // let curWalk;
  let curWalkX;
  let curIndex;

  let selectRegion = (reg) => {
    if (curReg === reg) {
      curReg = null;
    } else {
      curReg = reg;
      // curFinish;
      // curWalk;
    }
    console.log("selectRegion", curReg);
  };
  let selectFinish = (finish) => {
    if (curFinish === finish) {
      curFinish = null;
    } else {
      curFinish = finish;
      curWalk = null;
    }
    console.log("selectFinish", curFinish);
  };
  let selectWalk = (walkId, walks, i) => {
    curWalk = walkId;
    curWalkX = { walk: walkId, walks, i };
    console.log("selectWalk", curWalkX);
    // walkSelected(curWalk);
  };

  // let emitCurWalk = (curWalk) => {
  //   console.log("watch CurWalk", curWalk, curWalkX);
  //   dispatch("walkSelected", { curWalk });
  // };
  // $: emitCurWalk(curWalk);
</script>

<style lang="postcss">
  /*#regNav {*/
  .region,
  .finish,
  .walk {
    cursor: pointer;
  }
  .region {
    font-size: 0.8rem;
    color: rgb(73, 49, 29);

    &.current {
      border-left: thin solid #888;
      & > div.title {
        font-weight: bold;
        font-size: 1rem;
        background-color: rgb(73, 49, 29);
        color: white;
      }
    }
    & .finish {
      font-size: 0.8rem;
      margin-left: 1rem;
      color: rgb(122, 87, 58);
      &.current {
        border-left: thin solid #888;
        & > div.title {
          font-weight: bold;
          font-size: 1rem;
          background-color: rgb(122, 87, 58);
          color: white;
        }
      }
      & .walk {
        margin-left: 1rem;
        font-size: 0.8rem;
        color: red;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.current {
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }
  }
  /*}*/
</style>
