<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<svelte:window
  on:keydown={keyFunc}
  bind:innerHeight={winHeight}
  bind:innerWidth={winWidth}
/>
<div class="fancyBox">
  <div class="header">
    <button class="play" on:click|stopPropagation={togglePlay}>
      {#if playing}
        <IconPause fill="#ccc" />
      {:else}
        <IconPlay fill="#ccc" />
      {/if}
    </button>
    <div>{images[index].album}</div>
    <button class="close" on:click|stopPropagation={close}>
      <IconClose />
    </button>
  </div>
  <div class="photo">
    <div class="image-wrapper">
      {#each [images[index]] as photo (index)}
        <!-- transition:fade -->
        <button type="button" on:click={nextIndex}>
          <img
            class="image animated lazyload"
            src={photo.imageURL}
            srcset={photo.srcset}
            alt="walk"
          />
        </button>
      {/each}
      <!-- <img ref="images" class="image animated lazyload" v-for="(item, i) in images"  data-src={item.fullUrl} v-show="item.index===index+1" on:click.stop={addIndex} key={item.pid}> -->
      <div class="footer">
        <span class="caption">{image.photographer ?? ""}</span>
        <span class="caption">{image.caption ?? ""}</span>
        <button type="button" class="count" on:click v-show="showimagecount">
          {`${index + 1} of ${images.length}`}
        </button>
      </div>
    </div>

    <div class="arrows">
      <button on:click|stopPropagation={decIndex} class="aleft">
        {#if index > 0}
          <div class="arrowBox">
            <IconArrowLeft fill="#ccc" />
          </div>
        {/if}
      </button>
      <button on:click|stopPropagation={nextIndex} class="aright">
        {#if index < images.length - 1}
          <div class="arrowBox">
            <IconArrowRight fill="#ccc" />
          </div>
        {/if}
      </button>
    </div>
  </div>
</div>

<script>
  import { fade } from "svelte/transition";
  import { imageURL, mapAlbum } from "./GalleryMixin";
  // import 'lazysizes/lazysizes.min.js';
  import IconArrowRight from "./arrowRight.svelte";
  import IconArrowLeft from "./arrowLeft.svelte";
  import IconPause from "./Pause.svelte";
  import IconPlay from "./Play.svelte";
  import IconClose from "./Close.svelte";
  // const inBrowser = typeof window !== 'undefined';
  // const isFullScreen = inBrowser
  //   ? document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
  //   : false;
  // const isFullScreen = false;
  export let index;
  export let images;
  export let image;
  export let playing = false;
  export let isShow;
  // biome-ignore lint/style/useSingleVarDeclarator: <explanation>
  // biome-ignore lint/style/useConst: <explanation>
  let winHeight = window.innerHeight,
    winWidth = window.innerWidth;
  let playTimer = null;
  const nextRotateImg = () => {
    index = (index + 1) % images.length;
    console.log("FancyBox", "RotateImage", { index, isShow, playing });
  };
  $: {
    if (playing) {
      if (!playTimer) playTimer = setInterval(nextRotateImg, 2000);
    } else {
      playTimer && clearInterval(playTimer);
      playTimer = null;
    }
  }
  $: {
    console.log("FancyBox:", $$props);
  }
  $: {
    console.warn("fancyBox:", { index, isShow, playing });
  }

  // const getDataSizes = (pic) => {
  //   const ratio = Number.parseInt(pic.pwidth) / Number.parseInt(pic.pheight);
  //   console.log("getDataSize", winHeight, ratio, pic);
  //   const used = winWidth > 500 ? 82 + 40 + 28 : 42 + 20 + 24;
  //   return Math.min((winHeight - used) * ratio, winWidth * 0.95) + `px`;
  // };

  const decIndex = () => {
    const oldIndex = index;
    if (index > 0) index = index - 1;
    console.log("FancyBox - index change", oldIndex, "==>", index);
  };
  const nextIndex = () => {
    const oldIndex = index;
    if (index < images.length - 1) index = index + 1;
    console.log("FancyBox - index change", oldIndex, "==>", index);
  };
  const togglePlay = () => {
    playing = !playing;
    console.log("FancyBox", { playing, isShow });
  };
  const close = () => {
    console.log("FAncyBox closing ");
    playing = false;
    isShow = false;
  };
  const keyFunc = (event) => {
    // event.preventDefault();
    console.log("keyFunc", event);
    switch (event.key) {
      case "Escape": // Esc
        close();
        break;
      case "ArrowRight": // Left Arrow
        nextIndex();
        break;
      case "ArrowLeft": // Right Arrow
        decIndex();
        break;
    }
  };
</script>

<style lang="postcss">
  /* :root {
    --headerSize: 40px;
    --pagSize: 80px;
  }

  @media (max-width: 500px) {
    :root {
      --pagSize: 40px;
      --headerSize: 20px;
    }
  } */
  .fancyBox {
    position: relative;
    /* max-height: 100%; */
    display: grid;
    grid-template-rows: var(--headerSize) minmax(0, 1fr);
    text-align: center;
    align-items: center;

    & .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: calc(0.25 * var(--headerSize)) 20px 0 20px;

      & > button {
        width: calc(0.75 * var(--headerSize));
        height: calc(0.75 * var(--headerSize));

        cursor: pointer;
      }
    }
    & .photo {
      overflow: hidden;
    }
    & .image-wrapper {
      display: inline-block;
      position: relative;
      margin: 0 auto;
      max-height: var(--imageSize);
      overflow: hidden;

      & .image {
        position: relative;
        top: 0;
        left: 0;
        max-height: var(--imageSize);
        display: block;
        object-fit: contain;
      }

      & .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 5px;
        text-align: left;
        height: 24px;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-content: space-between;

        & span {
          display: inline-block;
          font-size: 14px;
          color: #fff;
          background-color: rgba(191, 191, 191, 0.75);
        }

        & .count {
          font-size: 12px;
          justify-self: flex-end;
        }
      }
    }

    @media screen and (min-width: 720px) {
      .image-wrapper .image {
        max-width: calc(100vw - var(--pagSize));
        /* max-height: calc(100vh - var(--headerSize) - var(--pagSize) - 24px); */
      }
      .arrows {
        & > button > div {
          height: 40px;
          width: 40px;
        }
      }
    }

    @media screen and (max-width: 720px) {
      .image-wrapper .image {
        max-width: 98vw;
        /* max-height: calc(100vh - var(--headerSize) - var(--pagSize) - 24px); */
      }
      .arrows {
        & > button > div {
          height: 20px;
          width: 20px;
        }
      }
    }

    & .arrows {
      background-color: transparent;
      display: grid;
      grid-template-columns: 1fr 1fr;

      position: absolute;
      top: var(--headerSize);
      height: 100%;
      padding-bottom: calc(1.5 * var(--headerSize));

      & > button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 50vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        &.aright {
          justify-content: flex-end;
        }
      }
    }
  }
</style>
