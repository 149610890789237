<svelte:options immutable={true} />

<div class="line">
  <div>{where === "H" ? "Home" : "Events"} Page</div>
  <div class="showing">
    {showing ? "👍🏽😄" : "🚫"}
  </div>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div class="toggle" on:click={() => (active = !active)} class:active />
  <!-- <input bind:checked={active} type="checkbox" /> -->
  {#if active}
    <div label="start">
      <DatePicker bind:selected={start} dispNil="now" />
    </div>
    <div label="end">
      <DatePicker bind:selected={end} dispNil={endDispNil} />
    </div>
  {/if}
</div>

<script>
  import DatePicker from "./DatePicker/DatePicker.svelte";

  import { format, parseISO } from "date-fns";
  import _ from "lodash";

  export let start;
  export let end;
  export let eventEnd;
  export let active;
  export let where;
  const today = format(new Date(), "yyyy-MM-dd");

  const log = (a, ...rest) =>
    echo.log(
      echo.blue.bold("EventEditDisplay"),
      echo.white.bgBlue.asTag(a),
      ...rest,
    );

  $: showing =
    active && today >= (start ?? today) && today <= (end ?? eventEnd ?? today);

  $: endDispNil = eventEnd ? "event end" : "never";
  $: log("data", $$props, { showing });
</script>

<style lang="postcss">
  .line {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
  }
  .line div {
    padding: 0 5px;
    position: relative;
  }
  .line :first-child {
    width: 8em;
  }

  [label]:after {
    content: attr(label);
    position: absolute;
    display: inline-block;
    left: 1em;
    top: -8px;
    background-color: white;
    font-size: 0.7em;
    color: slategray;
  }
  .showing {
    font-size: 1.5em;
    position: relative;
    top: -0.2em;
    width: 3em;
  }
  .toggle {
    width: 40px;
    height: 20px;
    border: 2px solid #444;
    border-radius: 10px;
    background-color: #888;
    position: relative;
    margin-right: 4px;
  }
  .toggle.active {
    background-color: green;
  }
  .toggle::after {
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;
    border: 2px solid #444;
    border-radius: 8px;
    font-size: 0.5em;
    background-color: white;
    top: -1px;
    left: -1px;
  }
  .toggle.active::after {
    left: 18px;
  }
</style>
