<div class="libraryPage">
  <div class="navBars">
    <div class="searchBy">
      <button on:click={() => (showByYear = true)}>
        <img
          class:inactive={!showByYear}
          src="/assets/image/searchByDate.jpg"
          alt="show by Year"
        />
      </button>
      <button on:click={() => (showByYear = false)}>
        <img
          class:inactive={showByYear}
          src="/assets/image/searchByRegion.jpg"
          alt="show by region"
        />
      </button>
    </div>
    {#if showByYear}
      <NavBarByYear walks={walksByYear} bind:curWalk />
    {:else}
      <NavBarByRegion walks={walksByRegion} bind:curWalk />
    {/if}
  </div>
  <div class="center">
    <div class="searchBy" v-show="curWalk">
      <!-- <div on:click="{()=>prevWalk"><img class="{inactive: curWalkIndex===0}" src="/assets/image/PrevFingerpost.jpg"></div> -->
      <button on:click={() => (showMap = true)}>
        <img
          class:inactive={!showMap}
          src="/assets/image/viewMap.jpg"
          alt="show map"
        />
      </button>
      <button on:click={() => (showMap = false)}>
        <img
          class:inactive={showMap}
          src="/assets/image/viewProfiles.jpg"
          alt="show profiles"
        />
      </button>
      <!-- <div on:click="{()=>nextWalk"><img class="{inactive: !curWalkBlock || curWalkIndex+1 > curWalkBlock.length}" src="/assets/image/NextFingerpost.jpg"></div> -->
    </div>

    {#if curWalk && !showMap}
      <WalkDetails walkId={curWalk} />
    {/if}
    {#if curWalk && showMap}
      <ShowMap walkId={curWalk} />
    {/if}
    <div />
    <div />
  </div>
</div>

<script>
  import { onMount } from "svelte";
  import NavBarByYear from "./_NavBarByYear.svelte";
  import NavBarByRegion from "./_NavBarByRegion.svelte";
  import WalkDetails from "../walk/WalkDetails.svelte";
  import ShowMap from "./_ShowMap.svelte";
  import { getWalkData } from "$lib/WalksMixin.js";
  import Banner from "@components/bannerStEds.svelte";
  import { update, forEach, groupBy } from "lodash";
  let curWalk = null;
  let showByYear = false;
  let walksByRegion = [];
  let walksByYear = [];
  let showMap = true;
  const newWalk = (walk) => {
    console.log("walk selected", walk);
    // curWalk = walk;
  };
  onMount(async () => {
    const response = await getWalkData("GetWalksByRegionIndex");
    let data = await response.json();
    console.log('GetWalksByRegionIndex', data)
    const byReg = {};
    for (const rec of data) {
      const region=rec.region?.regname ?? 'tbc';
      const finish=rec.finish??'tbc';
      if (!byReg[region])byReg[region]={};
      if (!byReg[region][finish])byReg[region][finish]=[];
      byReg[region][finish].push(rec)
    };
    console.log("walksByRegion", byReg);
    walksByRegion = byReg;
    data = data.sort((a, b) => a.date < b.date);
    const byYear = {};
    for (const rec of data) {
      const year = rec.date.substring(0,4)
      if(!byYear[year])byYear[year]=[];
      byYear[year].push(rec)      
    }
    console.log("walksByYear", byYear);
    walksByYear = byYear;
  });
</script>

<style>
  .libraryPage,
  .center {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .searchBy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .searchBy {
    /*width: 200px;
  max-width: 200px;*/
    justify-content: space-around;
    & img {
      width: 70px;

      &.inactive {
        opacity: 0.5;
      }
    }
  }
</style>
