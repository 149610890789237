<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->

<div>
  <div class="gallery">
    {#each images as item, indx}
      <div class="wrapper">
        <button type="button" on:click={() => selectImage(indx)}>
          <img
            data-src={item.imageUrl}
            data-srcset={mapAlbum(item.srcset)}
            src={item.imageUrl}
            srcset={mapAlbum(item.srcset)}
            data-sizes="auto"
            class="lazyload"
            alt
          />
        </button>
      </div>
    {/each}
  </div>
  <div>Click on any image to see a large version.</div>
</div>

<script>
  export let images;
  export let index;
  export let isShow;
  import { mapAlbum } from "./GalleryMixin";
  const selectImage = (indx) => {
    index = indx;
    isShow = true;
  };
</script>

<style lang="postcss">
  .gallery {
    display: flex;
    flex-wrap: wrap;
    row-gap: 3px;
  }

  .gallery .wrapper {
    display: flex;
    justify-content: center;
    background-color: antiquewhite;
    box-sizing: border-box;
    flex: 1;
    padding: 2px 1px;
  }

  .gallery .wrapper img {
    display: block;
    height: 100px;
    width: auto;
    border: 0px;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
  }
</style>
