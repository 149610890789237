<div>
  <p>
    Click on any of the links to read the PDF document on line. To download a
    document to your computer, right click on the link and select
    <i>save link as ...</i>.
  </p>
  <p>
    <a href="/apiServer/downloads/constitution" target="_blank"
      >The Club Constitution</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/newMemberInfo" target="_blank"
      >Information for New Member and Guests</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/leaderRoles" target="_blank"
      >Role &amp; responsibilities of a Walk Leader</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/busRoutes" target="_blank">Bus Route Map</a>
  </p>
  <p>
    <a href="/apiServer/downloads/applicationForm" target="_blank"
      >application form</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/flyer" target="_blank">Club Flyer</a>
  </p>
  <p>
    <a href="/apiServer/downloads/riskAssessment" target="_blank"
      >Generic Risk Assessment</a
    >
  </p>
</div>
